.fslightbox-toolbar {
  background: transparent;
}

.fslightbox-toolbar-button:first-child {
  display: none;
}

.fslightbox-slide-number-container {
  color: white;
  font-family: Apercu;
  font-size: 1.6rem;
}

.fslightbox-slide-btn {
  background: transparent;
}
