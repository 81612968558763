.mapboxgl-map {
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    overflow: hidden;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-align: left;
}

.mapboxgl-map:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.mapboxgl-canary {
    background-color: salmon;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
    cursor: -webkit-grab;
    cursor: grab;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-track-pointer {
    cursor: pointer;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate .mapboxgl-canvas {
    -ms-touch-action: pan-x pan-y;
        touch-action: pan-x pan-y;
}

.mapboxgl-canvas-container.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-drag-pan .mapboxgl-canvas {
    -ms-touch-action: pinch-zoom;
        touch-action: pinch-zoom;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas {
    -ms-touch-action: none;
        touch-action: none;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right { position: absolute; pointer-events: none; z-index: 2; }
.mapboxgl-ctrl-top-left     { top: 0; left: 0; }
.mapboxgl-ctrl-top-right    { top: 0; right: 0; }
.mapboxgl-ctrl-bottom-left  { bottom: 0; left: 0; }
.mapboxgl-ctrl-bottom-right { right: 0; bottom: 0; }

.mapboxgl-ctrl {
    clear: both;
    pointer-events: auto;

    /* workaround for a Safari bug https://github.com/mapbox/mapbox-gl-js/issues/8185 */
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.mapboxgl-ctrl-top-left .mapboxgl-ctrl     { margin: 10px 0 0 10px; float: left; }
.mapboxgl-ctrl-top-right .mapboxgl-ctrl    { margin: 10px 10px 0 0; float: right; }
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl  { margin: 0 0 10px 10px; float: left; }
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl { margin: 0 10px 10px 0; float: right; }

.mapboxgl-ctrl-group {
    border-radius: 4px;
    background: #fff;
}

.mapboxgl-ctrl-group:not(:empty) {
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.mapboxgl-ctrl-group button {
    width: 29px;
    height: 29px;
    display: block;
    padding: 0;
    outline: none;
    border: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
}

.mapboxgl-ctrl-group button + button {
    border-top: 1px solid #ddd;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

/* https://bugzilla.mozilla.org/show_bug.cgi?id=140562 */
.mapboxgl-ctrl button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.mapboxgl-ctrl-group button:focus {
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 150, 255, 1);
            box-shadow: 0 0 2px 2px rgba(0, 150, 255, 1);
}

.mapboxgl-ctrl button:disabled {
    cursor: not-allowed;
}

.mapboxgl-ctrl button:disabled .mapboxgl-ctrl-icon {
    opacity: 0.25;
}

.mapboxgl-ctrl button:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.mapboxgl-ctrl-group button:focus:focus-visible {
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 150, 255, 1);
            box-shadow: 0 0 2px 2px rgba(0, 150, 255, 1);
}

.mapboxgl-ctrl-group button:focus:not(:focus-visible) {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.mapboxgl-ctrl-group button:focus:first-child {
    border-radius: 4px 4px 0 0;
}

.mapboxgl-ctrl-group button:focus:last-child {
    border-radius: 0 0 4px 4px;
}

.mapboxgl-ctrl-group button:focus:only-child {
    border-radius: inherit;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url(/static/media/mapboxgl-ctrl-zoom-out.6a52a7aa.svg);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url(/static/media/mapboxgl-ctrl-zoom-in.0b972bc0.svg);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url(/static/media/mapboxgl-ctrl-compass.a94d5e86.svg);
}

a.mapboxgl-ctrl-logo {
    width: 88px;
    height: 23px;
    margin: 0 0 -4px -4px;
    display: block;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    background-image: url(/static/media/mapboxgl-ctrl-logo.08806268.svg);
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
    width: 23px;
}


.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    padding: 0 5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0;
}

@media screen {
    .mapboxgl-ctrl-attrib.mapboxgl-compact {
        min-height: 20px;
        padding: 0;
        margin: 10px;
        position: relative;
        background-color: #fff;
        border-radius: 3px 12px 12px 3px;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact:hover {
        padding: 2px 24px 2px 4px;
        visibility: visible;
        margin-top: 6px;
    }

    .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-attrib.mapboxgl-compact:hover,
    .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact:hover {
        padding: 2px 4px 2px 24px;
        border-radius: 12px 3px 3px 12px;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-inner {
        display: none;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact:hover .mapboxgl-ctrl-attrib-inner {
        display: block;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        content: '';
        cursor: pointer;
        position: absolute;
        background-image: url(/static/media/mapboxgl-ctrl-attrib.23bb7377.svg);
        background-color: rgba(255, 255, 255, 0.5);
        width: 24px;
        height: 24px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 12px;
    }

    .mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        bottom: 0;
        right: 0;
    }

    .mapboxgl-ctrl-top-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        top: 0;
        right: 0;
    }

    .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        top: 0;
        left: 0;
    }

    .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        bottom: 0;
        left: 0;
    }
}

.mapboxgl-ctrl-attrib a {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
}

.mapboxgl-ctrl-attrib a:hover {
    color: inherit;
    text-decoration: underline;
}

/* stylelint-disable-next-line selector-class-pattern */
.mapboxgl-ctrl-attrib .mapbox-improve-map {
    font-weight: bold;
    margin-left: 2px;
}

.mapboxgl-attrib-empty {
    display: none;
}

.mapboxgl-ctrl-scale {
    background-color: rgba(255, 255, 255, 0.75);
    font-size: 10px;
    border-width: medium 2px 2px;
    border-style: none solid solid;
    border-color: #333;
    padding: 0 5px;
    color: #333;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

@media print {
    /* stylelint-disable-next-line selector-class-pattern */
    .mapbox-improve-map {
        display: none;
    }
}

.fslightbox-toolbar {
  background: transparent;
}

.fslightbox-toolbar-button:first-child {
  display: none;
}

.fslightbox-slide-number-container {
  color: white;
  font-family: Apercu;
  font-size: 1.6rem;
}

.fslightbox-slide-btn {
  background: transparent;
}

